export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/api": [3],
		"/components": [4],
		"/concepts/client-validation": [~5],
		"/concepts/enhance": [6],
		"/concepts/error-handling": [~7],
		"/concepts/events": [~8],
		"/concepts/files": [9],
		"/concepts/messages": [~10],
		"/concepts/multiple-forms": [~11],
		"/concepts/nested-data": [~12],
		"/concepts/proxy-objects": [~13],
		"/concepts/snapshots": [~14],
		"/concepts/spa": [15],
		"/concepts/strict-mode": [16],
		"/concepts/submit-behavior": [17],
		"/concepts/tainted": [~18],
		"/concepts/timers": [~19],
		"/contributing": [20],
		"/crud": [21],
		"/debug": [~22],
		"/default-values": [23],
		"/examples": [24],
		"/faq": [25],
		"/flash-messages": [26],
		"/formsnap": [27],
		"/get-started/[...lib]": [~28],
		"/legacy": [29],
		"/migration-v2": [31],
		"/migration": [30],
		"/playground": [~32],
		"/rate-limiting": [33],
		"/sponsors": [34],
		"/super-debug": [35],
		"/support": [36],
		"/whats-new-v1": [37],
		"/whats-new-v2": [38]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';